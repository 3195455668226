/* src/Home.module.css */
.container {
  text-align: center;
  padding: 20px;
}

.recipeList {
  list-style: none;
  padding: 0;
}

.recipeButton {
  background-color: #a8a77a; /* Earthy green */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.recipeButton:hover {
  background-color: #4e9c81; /* Darker green for hover effect */
}
