/* Use a font that gives a soft, earthy feel, like 'Cormorant Garamond' */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&display=swap');

body {
  margin: 0;
  font-family: 'Cormorant Garamond', serif; /* Apply the soft font */
  background-color: #f4f1e9; /* A tan background for an earthy tone */
  color: #333; /* Dark grey color for text to ensure readability */
}

a {
  color: #4e9c81; /* Soft green color for links */
}

/* Styles for the navigation bar */
.navBar {
  background-color: #a8a77a; /* Earthy green for navbar background */
  color: #f4f1e9; /* Tan color for navbar text */
  padding: 10px 0;
  display: flex;
  justify-content: space-between; /* Adjust to space-between for alignment */
  align-items: center;
}

.navBar a {
  color: #f4f1e9; /* Tan color for links to ensure readability */
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition for background-color and color */
}

.navBar a:hover {
  background-color: #4e9c81; /* Earthy green for hover background */
  color: #fff; /* White color on hover */
}


/* Style for the website title in the navbar */
.title {
  font-size: 2em; /* Bigger size for the title */
  color: #4e9c81; /* Soft green color for the title */
  text-align: center;
  margin: 20px 0; /* Add margin for spacing */
  display: block; /* Ensures the title is on its own line */
}
