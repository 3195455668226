/* src/RecipeDetails.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
}

.title {
  font-size: 2.5em;
  color: #4e9c81;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.recipeLayout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 800px; /* Adjust based on your content */
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.details,
.ingredientsInstructions {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align the content */
  padding: 0 20px; /* Add padding on the sides */
  border: 1px solid #e0e0e0; /* Light grey border */
  border-radius: 5px; /* Slight rounding of corners */
  background-color: #fff; /* White background */
}

.detail-item,
.ingredientsInstructions h2,
.ingredientsInstructions ol {
  width: 100%; /* Full width for alignment */
  /*text-align: center; /* Center-align the text */
  margin: 10px 0; /* Add some margin for spacing */
  padding: 10px;
}

.ingredientsInstructions ul {
  list-style-type: none; /* Remove default list styling */
  padding-left: 0; /* Remove default padding */
}

.ingredientsInstructions ul li::before {
  content: ''; /* This will be the custom marker */
  display: inline-block; /* Allows us to give width and height */
  width: 1em; /* Width of the custom marker */
  height: 1px; /* Height of the custom marker */
  background: #000; /* Color of the custom marker */
  margin-right: 0.5em; /* Space between the marker and the list item text */
  vertical-align: middle; /* Aligns the marker with the text */
}

.detail-item strong {
  font-weight: bold; /* Make the key names bold */
}

ol {
  list-style-type: none; /* Remove default numbering */
  padding-left: 0; /* Remove padding if not needed */
}

ol li::before {
  content: none; /* Remove any custom numbering */
}
