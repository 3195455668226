/* src/Search.module.css */
.container {
  text-align: center;
  padding: 20px;
}

.form {
  margin-bottom: 20px;
}

.selectCategory {
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.searchInput {
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.searchButton {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #4e9c81;
  color: white;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #3a7a5e;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.th, .td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.tr:nth-child(even) {
  background-color: #f4f1e9;
}

.noResults {
  margin-top: 20px;
}
